import { Routes, Route, Navigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import Home from "./pages/Home";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import Oops from "./pages/Oops";

import "./styling/global.css";
import "./styling/fonts.css";

import staticData from "./data/staticData.json";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCFTemGJbYN34KZyyeo8fYbBk2vppnV-qA",
  authDomain: "veritas-web-solutions.firebaseapp.com",
  projectId: "veritas-web-solutions",
  storageBucket: "veritas-web-solutions.appspot.com",
  messagingSenderId: "339497362535",
  appId: "1:339497362535:web:feb2b6c2ccf6e6e821e0c2",
  measurementId: "G-6ZBE34CSVM",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
/* const analytics = getAnalytics(app); */

const App = () => {
  return (
    <HelmetProvider>
      <div className="app">
        <Header header={staticData.header} />
        <Routes>
          <Route path="/" element={<Navigate to="/Home" />} />
          <Route
            path="/Home"
            element={<Home home={staticData.home} app={app} />}
          />
          <Route path="/*" element={<Oops oops={staticData.oops} />} />
        </Routes>
        <Footer footer={staticData.footer} />
      </div>
    </HelmetProvider>
  );
};

export default App;
