const Footer = ({ footer }) => {
  return (
    <footer>
      <div className="social-links frfc hidden">
        {footer.socials.map((item, i) => (
          <div key={i} className="">
            <img
              src={require(`../data/logos/${item.src}`)}
              className="img-link"
              alt={item.alt}
            />
          </div>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
