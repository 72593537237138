import { useForm } from "react-hook-form";
import React, { useEffect } from "react";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";

const ContactForm = ({ contactData, socialData }) => {
  const { layout, services, contactInfo } = contactData;
  const db = getFirestore();
  const docCountRef = doc(db, "userContact", "DocumentCount");
  const upperBoundsForEmailing = 100;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const fetchDocumentCount = async () => {
    try {
      const docSnap = await getDoc(docCountRef);

      if (docSnap.exists()) {
        return docSnap.data().Count;
      }
    } catch (error) {
      console.log("Error getting document:", error);
      return false;
    }
  };

  let emailFormat = {
    to: ["veritaswebsolutions@gmail.com"],
    message: {
      subject: "A user has contacted us about their website",
      html: "",
    },
  };

  const onSubmit = async (data) => {
    const filteredData = Object.keys(data).reduce((obj, key) => {
      if (data[key]) {
        obj[key] = data[key];
      }
      return obj;
    }, {});

    const concatenatedString = Object.entries(filteredData).reduce(
      (str, [key, value]) => {
        return str + `${key}:${value}, `;
      },
      ""
    );

    const currentCount = await fetchDocumentCount();

    if (currentCount < upperBoundsForEmailing) {
      try {
        const docRef = doc(db, "userContact", filteredData.email);
        emailFormat.message.html = concatenatedString;
        await setDoc(docRef, emailFormat);
        await setDoc(docCountRef, { Count: currentCount + 1 });
        console.log(emailFormat);
        console.log("Document successfully written!");
        reset();
      } catch (error) {
        console.log("Error writing document:", error);
      }
    }
  };

  console.log(errors);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
      <div className="services">
        <div className="service-chunk">
          <p className="inspire-services">{layout.header}</p>
          <div className="service-list">
            {layout.options.map((item, i) => (
              <div className="service" key={i}>
                <input
                  type="checkbox"
                  name={item}
                  id={item}
                  {...register(`${item}`, {})}
                />
                <label className="normal-text" htmlFor={item}>
                  {item}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="service-chunk">
          <p className="inspire-services">{services.header}</p>
          <div className="service-list">
            {services.options.map((item, i) => (
              <div className="service" key={i}>
                <input
                  type="checkbox"
                  name={item}
                  id={item}
                  {...register(`${item}`, {})}
                />
                <label className="normal-text" htmlFor={item}>
                  {item}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="user-info-fields">
        <div className="user-name-area">
          <div>
            <label htmlFor="first-name" />
            <input
              placeholder={contactInfo.firstName}
              maxLength={50}
              autoComplete="given-name"
              type="text"
              id="first-name"
              name="first-name"
              {...register("firstName", {
                required: "First name required*",
                maxLength: {
                  value: 50,
                  message: "Exceeded max length",
                },
              })}
            />
            <p className="form-error-message ">{errors.firstName?.message}</p>
          </div>
          <div>
            <label htmlFor="last-name" />
            <input
              placeholder={contactInfo.lastName}
              maxLength={50}
              autoComplete="family-name"
              type="text"
              id="last-name"
              name="last-name"
              {...register("lastName", {
                required: "Last name required*",
                maxLength: {
                  value: 50,
                  message: "Exceeded max length",
                },
              })}
            />
            <p className="form-error-message ">{errors.lastName?.message}</p>
          </div>
        </div>
        <div className="user-email-area">
          <label htmlFor="email" />
          <input
            placeholder={contactInfo.email}
            maxLength={100}
            autoComplete="email"
            type="text"
            id="email"
            name="email"
            {...register("email", {
              required: "Email required*",
              maxLength: {
                value: 100,
                message: "Exceeded max length",
              },
            })}
          />
          <p className="form-error-message ">{errors.email?.message}</p>
        </div>
        <div className="user-description-area">
          <label htmlFor="user-description" />
          <textarea
            placeholder={contactInfo.userDescription}
            maxLength={1000}
            id="user-description"
            name="user-description"
            {...register("userDescription", {
              required: "Please give us some info about the site you want.*",
              maxLength: {
                value: 1000,
                message: "Exceeded max length",
              },
            })}
          />
          <p className="review-length">
            {watch("userDescription")?.length || 0} / 1000
          </p>
          <p className="form-error-message ">
            {errors.userDescription?.message}
          </p>
        </div>

        <button type="submit">Submit</button>

        {
          <div className="social-info frfc">
            {socialData.map((item, i) => (
              <div key={i} className="social-combo">
                <img
                  src={require(`../data/logos/${item.src}`)}
                  className="img-link"
                  alt={item.alt}
                />
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        }
      </div>
    </form>
  );
};

export default ContactForm;
