import { Link } from "react-router-dom";

const Oops = ({ oops }) => {
  return (
    <main className="fcfc oops">
      <p>{oops.errorMessage}</p>
      <nav>
        <Link className="internal-link" to={oops.button.route}>
          {oops.button.title}
        </Link>
      </nav>
    </main>
  );
};

export default Oops;
