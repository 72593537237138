import { HashLink } from "react-router-hash-link";

import UseScrollDirection from "../components/UseScrollDirection";

const Header = ({ header }) => {
  const scrollDirection = UseScrollDirection();

  return (
    <header
      className={`frfc ${scrollDirection === "down" ? "hide-header" : ""}`}
    >
      <div className="stipling frfc">
        <HashLink smooth to={header.logo.route}>
          <img
            className="header-logo"
            src={require(`../data/logos/${header.logo.src}`)}
            alt={header.logo.alt}
          />
        </HashLink>
        <nav className="frfc header-links">
          {header.navbar.map((item, i) => (
            <HashLink smooth className="internal-link" to={item.route} key={i}>
              {item.title}
            </HashLink>
          ))}
        </nav>
      </div>
    </header>
  );
};

export default Header;
