/* import { Link } from "react-router-dom"; */
import React, { useState, useEffect } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { HashLink } from "react-router-hash-link";
/* import { useState, useRef } from "react"; */

import ContactForm from "../components/ContactForm";
import restaurantDesignEx from "../data/imgs/restaurantDesignEx.png";
import interiorDesign from "../data/imgs/interiorDesignEx.png";

const Home = ({ home, app }) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedProject, setSelectedProject] = useState(0);

  const projects = home.dream.projects;

  const themeArray = [
    { src: restaurantDesignEx, alt: "Restaurant Design Example" },
    { src: interiorDesign, alt: "Interior Design Example" },
  ];

  const db = getFirestore(app);
  useEffect(() => {
    const docRef = doc(db, "reviews", "reviews");

    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const reviewsArray = docSnap.data().reviews;
          setReviews(reviewsArray);
        } else {
          console.log("No such document!");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error getting document:", error);
        setLoading(false);
      });
  }, []);

  const handlePrevClick = (setIndex, arr) => {
    setIndex((oldIndex) => {
      if (oldIndex === 0) {
        return arr.length - 1; // wrap around to the last image
      } else {
        return oldIndex - 1;
      }
    });
  };

  const handleNextClick = (setIndex, arr) => {
    setIndex((oldIndex) => (oldIndex + 1) % arr.length); // wrap around to the first image
  };

  return (
    <div id="top-home" className="home fcfc ">
      <div className="welcome stipling frfc">
        <p className="welcome-bg-letter">{home.welcome.bigLetter}</p>

        <div className="welcome-main ">
          <p className="welcome-header">{home.welcome.header}</p>
          <p>{home.welcome.smallText.ourName}</p>
          <nav>
            <HashLink
              smooth
              className="internal-link"
              to={home.welcome.smallText.learnMore.route}
            >
              {home.welcome.smallText.learnMore.text}
            </HashLink>
          </nav>
        </div>
        <div className="welcome-secondary">
          {home.welcome.tripleHeader.map((item, i) => (
            <p className="triple-header" key={i}>
              {item}
            </p>
          ))}
        </div>
      </div>
      <main className="home-content-container fcfc">
        <div className="main-page-bg-letters-container">
          {home.hugeLetters.map((item, i) => (
            <p className="main-page-bg-letters" key={i}>
              {item}
            </p>
          ))}
        </div>
        <div id="previous-work-area" className="dream stipling content-block">
          <div className=" stipling">
            <p className="bigWord-rules bigWord-coloring ">
              {home.dream.bigWord}
            </p>
          </div>
          <div className="frfc dream-content">
            <div className="dream-text">
              <h2>{home.dream.header}</h2>
              <p className="normal-text">{home.dream.content}</p>
            </div>
            <div className="dream-img-container">
              <div className="theme-ex frfc">
                <button
                  onClick={() => handlePrevClick(setSelectedProject, projects)}
                >
                  &lt;
                </button>
                <div>
                  <p className="dream-text-size">
                    {projects[selectedProject].employer}
                  </p>
                  <a
                    href={projects[selectedProject].route}
                    className="dream-text-size"
                  >
                    {projects[selectedProject].title}
                  </a>
                  <p className="dream-text-size">
                    {projects[selectedProject].description}
                  </p>
                </div>
                <button
                  onClick={() => handleNextClick(setSelectedProject, projects)}
                >
                  &gt;
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          id="testimonials-area"
          className="reviews fcfc stipling content-block"
        >
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="raised-container fcfc">
              <h2>{reviews[0].client}</h2>
              <p className="normal-text">{reviews[0].review}</p>
            </div>
          )}
        </div>

        <div id="services-area" className="inspire stipling content-block">
          <div className="frfc inspire-content">
            <div className="inspire-primary above-inspire-bigword">
              <h2>{home.inspire.areaOne.heading}</h2>
              <p className="normal-text">{home.inspire.areaOne.text}</p>
            </div>
            <div className="raised-container inspire-secondary inspire-services-container">
              {home.inspire.areaOne.services.map((item, i) => (
                <p className="inspire-services" key={i}>
                  {item}
                </p>
              ))}
            </div>
          </div>
          <div className="stipling">
            <p className="bigWord-rules bigWord-coloring ">
              {home.inspire.bigWord}
            </p>
          </div>
          <div className="frfc inspire-content below-inspire-bigword">
            <div className="inspire-primary">
              <h2>{home.inspire.areaTwo.heading}</h2>
              <p className="normal-text">{home.inspire.areaTwo.textOne}</p>
              <p className="normal-text">{home.inspire.areaTwo.textTwo}</p>
            </div>
            <div className="raised-container inspire-secondary-below page-area">
              <div className="theme-ex frfc">
                <button
                  onClick={() =>
                    handlePrevClick(setSelectedImageIndex, themeArray)
                  }
                >
                  &lt;
                </button>
                <img
                  src={themeArray[selectedImageIndex].src}
                  alt={themeArray[selectedImageIndex].alt}
                />
                <button
                  onClick={() =>
                    handleNextClick(setSelectedImageIndex, themeArray)
                  }
                >
                  &gt;
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          id="contact-area"
          className="contact-us fcfc stipling content-block"
        >
          <div className="raised-container fcfc">
            <h2> {home.contactUs.header}</h2>
            <ContactForm
              contactData={home.contactUs.contactForm}
              socialData={home.contactUs.socials}
            />
          </div>
        </div>

        <div className="projects-link-container stipling">
          {/*           <nav>
            <HashLink className="internal-link" to={home.otherProjects.route}>
              {home.otherProjects.title}
            </HashLink>
          </nav> */}
        </div>
      </main>
    </div>
  );
};

export default Home;
